<template>
  <div class="skill-info">
    <div class="skill-info_wrapper">
      <div class="skill-info__info overflow-ellipsis">
        <p class="skill-info__language-pair"
           @mouseleave="closeTooltip"
           @mouseover="openTooltip(languagePair, $event)">{{ languagePair }}</p>
        <p class="category-level">
          <span v-if="isInTolkeregister"
                class="imdi-icon"
                @mouseleave="closeTooltip"
                @mouseover="openTooltip($gettext('IMDi synced'), $event)">
          </span>
          <span v-if="qualificationShortName">({{ qualificationShortName }})</span>
        </p>
      </div>
      <div class="skill-info__skill-status">
        <sk-chip :label="skillStatusText"
                 :color="skillStatusColor" />
      </div>
    </div>
  </div>
</template>

<script>
  import helpers from '@/helpers';
  import ToolTipMixin from '@/mixins/ToolTipMixin';

  export default {
    mixins: [ToolTipMixin],
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        selectedStatus: ''
      };
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      languages() { return this.$store.state.selectsData.language || []; },
      qualificationsList() { return this.$store.getters.interpretationQualifications; },
      langFromId() { return this.propData?.langFromId; },
      langToId() { return this.propData?.langToId; },
      langFrom() { return this.languages?.find((lang) => lang.id === this.langFromId)?.name; },
      langTo() { return this.languages?.find((lang) => lang.id === this.langToId)?.name; },
      languagePair() { return this.langFrom + ' - ' + this.langTo; },
      qualification() { return this.qualificationsList?.find((quali) => quali.id === this.propData?.qualificationId); },
      qualificationShortName() { return this.qualification?.shortName; },
      isInTolkeregister() { return this.qualification?.isInTolkeregister; },
      // category() { return (this.qualification?.name && this.isInTolkeregister) ? this.qualification.name.split(':')[0] : ''; },
      skillStatusText() {
        return this.skillStatus?.text || '';
      },
      skillStatusColor() {
        return this.skillStatus?.color || 'white';
      },
      skillStatus() {
        return helpers.getAdminInfo.skillStatus(this, this.selectedStatus);
      },
      skillStatusList() {
        return [
          {
            id: 'approved',
            name: this.$gettext('Approved')
          },
          {
            id: 'learning',
            name: this.$gettext('Learning')
          },
          {
            id: 'pending',
            name: this.$gettext('Pending')
          },
          {
            id: 'declined',
            name: this.$gettext('Declined')
          }
        ];
      },
    },
    methods: {
      catchSkillStatusChange(value) {
        this.selectedStatus = value;
      }
    },
    mounted() {
      this.selectedStatus = this.propData?.status;
    }
  };
</script>
<style scoped>
.skill-info {
  width: 100%;
  height: 100%;
}

.skill-info_wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  max-height: 50px;
  margin: 2px 0;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: #f4f5f7;
}

.skill-info__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 13px;
}

.skill-info__language-pair {
  cursor: pointer;
}

.skill-info__skill-status {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 2px;
}

.category-level {
  color: #555;
}

.imdi-icon {
  display: inline-block;
  width: 18px;
  height: 100%;
  background-image: url(~@assets/imgs/undefined_imgs/certificate_icon_new.svg);
  background-position: bottom;
  background-repeat: no-repeat;
}
</style>
