<template>
  <section class="qualification-filter">
    <sk-multiselect :items-list="qualificationsList"
                    :preselected-value="qualifications"
                    :default-value="qualificationPlaceholder"
                    @checkboxeschanged="onInputBoxChanged" />
  </section>
</template>

<script>
  export default {
    data() {
      return {
        qualifications: []
      };
    },
    computed: {
      qualificationPlaceholder() { return this.$gettext('Qualification'); },
      qualificationsList() { return this.$store.getters.interpretationQualifications; }
    },
    methods: {
      onInputBoxChanged(value) {
        this.qualifications = [...value];
        if (this.qualifications === '') {
          return;
        }
        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('inList', value);
        });
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.qualifications = '';
        }
      }
    }
  };
</script>

<style>
.qualification-filter .sk-select {
  background-color: #fff;
}

.qualification-filter .sk-select__list {
  width: fit-content;
}

.qualification-filter .sk-select__value {
  color: #333;
  font-weight: normal;
  font-size: 13px;
}

.qualification-filter {
  width: 100%;
}
</style>
