<script>
  import CTwoRow from '@/components/grid/cells/shared/CTwoRow';

  export default {
    extends: CTwoRow,
    computed: {
      createdAt() { return this.propData?.createdAt; },
      createdAtDate() { return this.$moment(this.createdAt).format('DD.MM.YY'); },
      createdAtTime() { return this.$moment(this.createdAt).format('HH:mm') || '-'; },
      firstRow() { return this.createdAtDate; },
      secondRow() { return this.createdAtTime; }
    },
  };
</script>
