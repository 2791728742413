// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/certificate_icon_new.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.skill-info[data-v-081d2f01] {\n  width: 100%;\n  height: 100%;\n}\n.skill-info_wrapper[data-v-081d2f01] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  height: 100%;\n  max-height: 50px;\n  margin: 2px 0;\n  padding: 4px 8px;\n  border-radius: 8px;\n  background-color: #f4f5f7;\n}\n.skill-info__info[data-v-081d2f01] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: start;\n      -ms-flex-align: start;\n          align-items: flex-start;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  font-size: 13px;\n}\n.skill-info__language-pair[data-v-081d2f01] {\n  cursor: pointer;\n}\n.skill-info__skill-status[data-v-081d2f01] {\n  margin-top: auto;\n  margin-bottom: auto;\n  padding-left: 2px;\n}\n.category-level[data-v-081d2f01] {\n  color: #555;\n}\n.imdi-icon[data-v-081d2f01] {\n  display: inline-block;\n  width: 18px;\n  height: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: bottom;\n  background-repeat: no-repeat;\n}\n", ""]);
// Exports
module.exports = exports;
