<script>
  import CNotes from '@/components/grid/cells/job/CNotes';

  export default {
    extends: CNotes,
    computed: {
      notes() { return this.params.data?.note; },
      firstRow() { return this.notes?.note || ''; },
      secondRow() { return this.notes?.author || this.notes?.authorName || ''; }
    },
    methods: {
      onNoteClick() {
        this.$store.commit('UserNotesStore/setUserInfo', this.params.data);
        this.$store.commit('UserNotesStore/setCurrentUserId', this.params?.data.uid);
        this.$store.commit('ModalStore/setModal', {
          component: 'notes-modal',
          classes: ['sk-modal--new'],
          width: 750,
          data: {
            title: this.$gettext('Notes'),
            context: this,
            successHandlingCallbackName: 'refreshGrid'
          }
        });
      },
      refreshGrid() {
        this.$store.commit('AllInterpretationSkillsStore/refreshInterpreterSkillGrid');
      }
    }
  };
</script>
